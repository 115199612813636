import React from "react"
import { Container } from "@material-ui/core"
import { useButtonStyles } from "../../styles/styles"

const Hero = ({ image, title, subtitle, action, actionUrl, children }) => {
  return (
    <section className="hero-section">
      <Container maxWidth="lg">
        <div className="hero-section__content">
          <h1>{title}</h1>
          {subtitle && <h5>{subtitle}</h5>}
        </div>
      </Container>
    </section>
  )
}

export default Hero
