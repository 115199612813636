import { makeStyles } from "@material-ui/core/styles"

export const $red = "#FA5673"
export const $darkRed = "#F43E5F"

const sharedButtonStyles = {
  border: 0,
  height: 39,
  padding: "0 30px",
  fontSize: 14,
  fontWeight: 600,
}

export const useButtonStyles = makeStyles({
  secondaryButton: {
    ...sharedButtonStyles,
    background: "white",
    color: $red,
    "&:hover": {
      background: "rgba(255,255,255,0.85)",
    },
  },
  primaryButton: {
    ...sharedButtonStyles,
    background: $red,
    color: "white",
    "&:hover": {
      background: "#F43E5F",
    },
  },
  outlinedButton: {
    ...sharedButtonStyles,
    background: "transparent",
    color: "white",
    border: "2px  solid white",

    "&:hover": {
      background: "rgba(255,255,255,0.2)",
    },


  }
})
