import React, { useEffect, useState } from "react"
import {
  AppBar,
  Typography,
  Container,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Drawer,
  Collapse,
} from "@material-ui/core"

import { Link } from "gatsby"

import DropdownIcon from "../../icons/dropdown.svg"
import MenuIcon from "../../icons/menu.svg"
import { ExpandMore, ExpandLess } from "@material-ui/icons"
import { PAGE_URLS } from "../../enum/PageUrls"

const links = [
  { name: "Home", url: "/" },
  {
    name: "Events",
    sublinks: [
      { name: "Weekly Events", url: PAGE_URLS.ACTIVITIES },
      { name: "Upcoming Events", url: PAGE_URLS.UPCOMING_ACTIVITIES },
    ],
  },
  { name: "Services", url: PAGE_URLS.SERVICES },
  {
    name: "Facilities",
    sublinks: [{ name: "Available Facilities", url: PAGE_URLS.FACILITIES }],
  },
  { name: "Gallery", url: PAGE_URLS.GALLERY },
  { name: "Contact", url: PAGE_URLS.CONTACT },
  // { name: "Wedding Brochure", url: PAGE_URLS.WEDDING_BROCHURE, externalLink: true },
  {
    name: "Menus",
    sublinks: [
      {
        name: "Fish Fry Menu",
        url: PAGE_URLS.FISHFRY_MENU,
        externalLink: true,
      },
      {
        name: "Fish Fry Lunch Menu",
        url: PAGE_URLS.FISHFRY_LUNCH_MENU,
        externalLink: true,
      },
      {
        name: "Lent Fish Fry Menu",
        url: PAGE_URLS.LENT_FISHFRY_MENU,
        externalLink: true,
      },
      { name: "Weekly Menu", url: PAGE_URLS.WEEKLY_MENU, externalLink: true },
    ],
  },
]

const NavMenu = ({ name, sublinks }, key) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const isOpen = anchorEl ? "open" : "closed"

  return (
    <div className={`navbar__dropdown ${isOpen}`} key={key}>
      <p
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="navbar__link"
      >
        {name}
        <span className="dropdown-icon">
          <DropdownIcon />
        </span>
      </p>
      <Menu
        className="navbar__dropdown-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {sublinks.map((link, index) =>
          link.externalLink ? (
            <a href={link.url} key={index}>
              <MenuItem>{link.name}</MenuItem>
            </a>
          ) : (
            <Link to={link.url} key={index}>
              <MenuItem onClick={handleClose}>{link.name}</MenuItem>
            </Link>
          )
        )}
      </Menu>
    </div>
  )
}

const MobileNavMenu = ({ name, sublinks }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <span>
      <ListItem
        button
        onClick={() => setIsOpen(!isOpen)}
        className="drawer-link"
      >
        <ListItemText primary={name} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {sublinks.map((sublink, index) => {
            return sublink.externalLink ? (
              <a href={sublink.url} className="navbar__link" key={index}>
                <ListItem button className="drawer-sublink">
                  <ListItemText primary={sublink.name} />
                </ListItem>
              </a>
            ) : (
              <Link to={sublink.url} className="navbar__link" key={index}>
                <ListItem button className="drawer-sublink">
                  <ListItemText primary={sublink.name} />
                </ListItem>
              </Link>
            )
          })}
        </List>
      </Collapse>
    </span>
  )
}

const DesktopNavbar = ({ className }) => (
  <AppBar color="secondary" position="absolute" className={className}>
    <Container maxWidth="lg">
      <div className="navbar__container">
        <Typography variant="h1" className="navbar__brand">
          <Link to="/">KC Hall of O'Fallon</Link>
        </Typography>

        <div className="navbar__link-container desktop-only">
          {links.map((link, index) =>
            link.sublinks ? (
              <NavMenu {...link} key={index} />
            ) : link.externalLink ? (
              <a
                key={index}
                className="navbar__link"
                target="_blank"
                href={link.url}
              >
                {link.name}
              </a>
            ) : (
              <Link to={link.url} className="navbar__link" key={index}>
                {link.name}
              </Link>
            )
          )}
        </div>
      </div>
    </Container>
  </AppBar>
)

const MobileNavbar = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState()

  return (
    <AppBar color="secondary" position="fixed" className={className}>
      <span className="navbar__menu-icon" onClick={() => setIsMenuOpen(true)}>
        <MenuIcon />
      </span>

      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        className="navbar__drawer"
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          style={{ minWidth: 220, width: "100%" }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              KC Hall of O'Fallon
            </ListSubheader>
          }
        >
          {links.map((link, index) =>
            link.sublinks ? (
              <MobileNavMenu {...link} key={index} />
            ) : link.externalLink ? (
              <ListItem
                key={index}
                button
                component="a"
                href={link.url}
                target="_blank"
              >
                <ListItemText primary={link.name} className="drawer-link" />
              </ListItem>
            ) : (
              <Link to={link.url} className="navbar__link" key={index}>
                <ListItem button>
                  <ListItemText primary={link.name} className="drawer-link" />
                </ListItem>
              </Link>
            )
          )}
        </List>
      </Drawer>
    </AppBar>
  )
}

const Navbar = () => {
  const [navbarClass, setNavbarClass] = useState("")
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        const scrollY = window.scrollY

        if (scrollY > 10 && !navbarClass) {
          setNavbarClass("scrolled")
        }

        if (scrollY === 0) {
          setNavbarClass("")
        }
      },
      true
    )
  }, [])

  const sharedClasses = `navbar ${navbarClass}`
  const desktopNavbarClasses = sharedClasses + " desktop-only"
  const mobileNavbarClasses = sharedClasses + " mobile-only"

  return (
    <span>
      <DesktopNavbar className={desktopNavbarClasses} />
      <MobileNavbar className={mobileNavbarClasses} />
    </span>
  )
}

export default Navbar
