import pdf from "../static/wedding-brochure.pdf"
import bingoMenu from "../static/bingo-menu.png"
import fishfryInfo from "../static/fishfry-info.png"
import fishfryMenu from "../static/fish-fry-menu.png"
import fishfryLunchMenu from "../static/fish-fry-lunch-menu.png"
import weeklyMenu from "../static/weekly-menu.png"
import lentFishFryMenu from "../static/lent_fishfry_menu.png"

const getServiceIdLink = serviceUrl => PAGE_URLS.SERVICES + "/#" + serviceUrl

export const PAGE_URLS = {
  ABOUT_US: "/about-us",
  CONTACT: "/contact",
  FACILITIES: "/facilities",
  PREFERRED_VENDORS: "/preferred-vendors",
  ACTIVITIES: "/activities",
  UPCOMING_ACTIVITIES: "/upcoming-activities",
  EVENTS: "/events",
  SERVICES: "/services",
  GALLERY: "/gallery",
  WEDDING_BROCHURE: pdf,
  // FISHFRY_INFO: fishfryInfo,
  LENT_FISHFRY_MENU: lentFishFryMenu,
  FISHFRY_LUNCH_MENU: fishfryLunchMenu,
  FISHFRY_MENU: fishfryMenu,
  WEEKLY_MENU: weeklyMenu,
  BINGO_MENU: bingoMenu
}

export const SERVICE_IDS = {
  WEDDING_RECEPTIONS: "wedding-receptions",
  CORPORATE_FUNCTIONS: "corporate-functions",
  HAPPIER_HOLIDAYS: "happier-holidays",
  FUNERAL_SERVICES: "funeral-services",
  FUNDRAISERS: "fundraisers",
  BIRTHDAY_PARTIES: "birthday-parties"
}

export const SERVICE_URLS = {
  WEDDING_RECEPTIONS: getServiceIdLink(SERVICE_IDS.WEDDING_RECEPTIONS),
  CORPORATE_FUNCTIONS: getServiceIdLink(SERVICE_IDS.CORPORATE_FUNCTIONS),
  HAPPIER_HOLIDAYS: getServiceIdLink(SERVICE_IDS.HAPPIER_HOLIDAYS),
  FUNERAL_SERVICES: getServiceIdLink(SERVICE_IDS.FUNERAL_SERVICES),
  FUNDRAISERS: getServiceIdLink(SERVICE_IDS.FUNDRAISERS),
  BIRTHDAY_PARTIES: getServiceIdLink(SERVICE_IDS.BIRTHDAY_PARTIES)
}