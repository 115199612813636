import React, { useState } from "react"
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  withStyles,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
} from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import useForm from "../../hooks/useForm"
import { useButtonStyles, $red } from "../../styles/styles"
import { config } from "../../../config"
import ReCAPTCHA from "react-google-recaptcha";


const ValidationTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      // color: "white",
      color: $red,
    },
    "& label": {
      color: "#333",
    },
    "& input": {
      color: "#333 !important",
    },
    "& textarea": {
      color: "#333",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "rgba(0,0,0,0.15)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: $red,
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "orange",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0,0,0,0.15)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0,0,0,0.4)",
      },
      "&.Mui-focused fieldset": {
        borderColor: $red,
      },
    },
  },
})(TextField)

const MESSAGE_STATUS = {
  SENT: "sent",
  SENDING: "sending",
  NOT_SENT: "notSent",
  SENT_ERROR: "sentError",
}

const ContactUsSection = () => {

  const { values, handleChange, handleSubmit, setErrors, errors } = useForm(
    submit
  )
  const [messageStatus, setMessageStatus] = useState(MESSAGE_STATUS.NOT_SENT)
  const sharedInputProps = {
    onChange: handleChange,
    margin: "normal",
    style: { width: "100%" },
    className: "mb-1",
    autoComplete: "autocomplete_off_hack_xfr4!k",
    variant: "outlined",
  }

  function formIsInvalid() {
    const { email, name, description } = values || {}

    const recentErrors = {}

    if (!email) recentErrors.email = "required"
    if (!name) recentErrors.name = "required"
    if (!description) recentErrors.description = "required"
    if (description && description.length < 30) recentErrors.description = "description must be at least 30 characters"

    setErrors(recentErrors)

    return Object.keys(recentErrors).length > 0
  }

  async function submit() {
    // do not send if already sent

    if (messageStatus === MESSAGE_STATUS.SENT) return
    if (formIsInvalid()) return
    if (!passCaptcha && process.env.NODE_ENV !== "development") return

    setMessageStatus(MESSAGE_STATUS.SENDING)

    const url = config.emailServerUrl
    const body = {
      toEmail: config.emailServerToEmail,
      fromEmail: values.email,
      name: values.name,
      subject: "More Information Request",
      body: values.description,
    }

    try {
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors", // no-cors, *cors, same-origin
      })
      await response.json()
      setMessageStatus(MESSAGE_STATUS.SENT)
    } catch (e) {
      setMessageStatus(MESSAGE_STATUS.SENT_ERROR)
    }
  }

  const styles = useButtonStyles()
  const messageSent = messageStatus === MESSAGE_STATUS.SENT
  const messageSending = messageStatus === MESSAGE_STATUS.SENDING
  const messageNotSent = messageStatus === MESSAGE_STATUS.NOT_SENT
  const messageSentError = messageStatus === MESSAGE_STATUS.SENT_ERROR

  const showCaptcha = (() => {
    const { email, name, description } = values || {}
    return email && name && description && description.length >= 30 && !passCaptcha
  })

  const [passCaptcha, setPassCaptcha] = useState(false)
  const handleCaptchaSuccess = value => {
    setPassCaptcha(true)
  }

  return (
    <span id="contact-us-block">
      <div className="contact-us-block" id="contact-us">
        <Container maxWidth="lg">
          <div className="contact-us-block__container">
            <Grid container spacing={0}>
              <Grid
                item
                md={6}
                xs={12}
                className="contact-us-block__form-section"
              >
                <div className="form-section">
                  <Typography variant="h4" className="lined-title">
                    Request Us!
                  </Typography>
                  <Typography variant="body1" paragraph className="mt-2">
                    Want to book us for your next event or fundraiser? Have
                    questions or concerns about using us for your next event?
                    Let us know!
                  </Typography>
                </div>
                <form autoComplete="new-password" style={{ marginBottom: 0 }}>
                  <ValidationTextField
                    label="Name"
                    name="name"
                    value={values["name"]}
                    helperText={errors["name"]}
                    error={errors["name"]}
                    {...sharedInputProps}
                  />
                  <ValidationTextField
                    label="Email"
                    name="email"
                    value={values["email"]}
                    helperText={errors["email"]}
                    error={errors["email"]}
                    {...sharedInputProps}
                  />
                  <ValidationTextField
                    label="Description"
                    name="description"
                    value={values["description"]}
                    multiline
                    rows="3"
                    helperText={errors["description"] || "Description must be at least 30 characters."}
                    error={errors["description"]}
                    {...sharedInputProps}
                  />

                  {/* //testomg */}
                  {/* 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI */}

                  {/* //real */}
                  {/* 6LcTAqYZAAAAAE0ybHplxVEtAV9eUyHn-0NbiQXJ */}

                  <div className={`recaptcha`} style={showCaptcha() ? {} : { "display": "none" }}>
                    <ReCAPTCHA
                      onChange={handleCaptchaSuccess}
                      sitekey="6LcTAqYZAAAAAE0ybHplxVEtAV9eUyHn-0NbiQXJ"
                    />
                  </div>

                  <Button
                    style={{ marginTop: 20, width: "100%" }}
                    className={styles.primaryButton}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={messageSending}
                  >
                    {messageNotSent && "SEND INQUIRY"}
                    {messageSent && "SENT!"}
                    {messageSentError && "ERROR SENDING MESSAGE"}
                    {messageSending && <CircularProgress size={20} />}
                  </Button>
                </form>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className="contact-us-block__info-section"
              >
                <div className="form-section">
                  <Typography variant="h4" className="lined-title white">
                    Information
                  </Typography>
                </div>
                <div className="info-container ">
                  <div className="info">
                    <Typography variant="body1" className="info-title">
                      Location:
                    </Typography>
                    <Typography variant="body1" className="info-body">
                      402 E. Highway 50
                      <br />
                      0'Fallon IL, 62269
                    </Typography>
                  </div>
                  <div className="info">
                    <Typography variant="body1" className="info-title">
                      Call Us:
                    </Typography>
                    <Typography variant="body1" className="info-body">
                      Call or text {config.managerName} at{" "}
                      <a href={`tel:${config.phoneNumber}`}>{config.phoneNumber}</a>
                    </Typography>
                  </div>
                  <div className="info">
                    <Typography variant="body1" className="info-title">
                      Send Us An Email:
                    </Typography>
                    <Typography variant="body1" className="info-body">
                      Use the Contact form on the right
                      <br /> or email us at <a href={`mailto:${config.phone}`}>{config.emailServerToEmail}</a>
                    </Typography>
                  </div>
                  <a
                    href="https://goo.gl/maps/G1uLHPGnk7fdcP2Y8"
                    target="_blank"
                  >
                    <Button
                      style={{ marginTop: 65, width: "100%" }}
                      className={styles.outlinedButton}
                      variant="contained"
                    >
                      Get Directions
                    </Button>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      <AppBar position="static" color="secondary" className="contact-us-footer">
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <FacebookIcon />
            </IconButton>
            <Typography variant="body2">
              <a
                href={`${config.facebookLink}`}
                target="_blank"
              >
                Follow Us!
              </a>
            </Typography>

            <Typography variant="body2" className="website-promotion-link">
              <a target="_blank" href="http://saxal.me">Need a Website?</a>
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </span>
  )
}

export default ContactUsSection
