/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang = "en-US", meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl || "https://www.ofallonkchall.com"
  const image = "https://i.imgur.com/hrjlJeF.jpg"

  function generateKeywords() {
    const locations = [
      "O'Fallon",
      "O'Fallon IL",
      "O'Fallon Illinois",
      "Illinois",
      "Belleville",
      "Belleville IL",
    ]

    const events = [
      "event",
      "Event",
      "Wedding",
      "wedding",
      "wedding reception",
      "reception",
      "fundraiser",
      "sports",
      "public",
      "public bar",
      "event hall",
      "bingo",
      "fish fry",
      "queen of hearts",
      "Queen of Hearts",
    ]

    let locationEvents = []

    locations.forEach(location => {
      events.forEach(event => {
        locationEvents.push(`${location} ${event}`)
      })
    })

    return locationEvents.join(",")
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s `}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `author`,
          content: `Alan Sax, saxal28@gmail.com`,
        },
        {
          name: `Classification`,
          content: `Business`,
        },
        {
          name: `url`,
          content: siteUrl,
        },
        {
          name: `identifier-URL`,
          content: siteUrl,
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
        {
          name: `keywords`,
          content: generateKeywords(),
        },
        {},
      ].concat(meta)}
    >
      <link rel="canonical" href="https://www.ofallonkchall.com" />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${siteUrl}",
          "name": "KC Hall of O'Fallon IL",
          "image": "${image}",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "618-632-6229",
            "contactType": "Business"
          },
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "O'Fallon",
            "addressRegion": "Illinois",
            "addressCountry": "United States",
            "postalCode": "62269"
          },
          "sameAs": ["https://www.facebook.com/KnightsOfColumbus4239/"]
        }`}
      </script>
      <script src="https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
